import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap"
import logo from "./logo.svg"

const NavbarComp = ({ bg, border, children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    url
                    statcounterProject
                    statcounterSecurity
                    enableManychat
                    phone
                    email
                }
            }
        }
    `)

    return (
        <Navbar
            bg={bg ? bg : "dark"}
            variant="dark"
            expand="sm"
            style={{ marginBottom: "-1px" }}
            className={border && "navbar-border-bottom"}
        >
            <Container>
                {data.site.siteMetadata.title && (
                    <Navbar.Brand href="/" className="font-weight-bold">
                        <img
                            src={logo}
                            style={{ width: "50px", marginRight: "1rem" }}
                        />
                        {data.site.siteMetadata.title}
                    </Navbar.Brand>
                )}

                <Nav className="ml-auto">{children}</Nav>
            </Container>
        </Navbar>
    )
}

export default NavbarComp
