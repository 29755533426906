import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Seo from "./Seo"
import Container from "react-bootstrap/Container"
import Jumbotron from "react-bootstrap/Jumbotron"
import "../scss/base.css" 

const Layout = ({ fluid, children, pageTitle, jumbotronBg, style }) => {
    const data = useStaticQuery(graphql`
        query SiteQuery {
            site {
                siteMetadata {
                    title
                    description
                    url
                    statcounterProject
                    statcounterSecurity
                    enableManychat
                    email
                    phone
                }
            }
        }
    `)

    return (
        <>
            <Navbar />
            <Seo
                title={pageTitle}
                websiteTitle={data.site.siteMetadata.title}
                description={data.site.siteMetadata.description}
                url={data.site.siteMetadata.url}
                statcounterProject={data.site.siteMetadata.statcounterProject}
                statcounterSecurity={data.site.siteMetadata.statcounterSecurity}
                enableManychat={data.site.siteMetadata.enableManychat}
            />
            {pageTitle && (
                <Jumbotron
                    className={
                        jumbotronBg
                            ? `bg-${jumbotronBg} text-white rounded-0`
                            : `bg-dark text-white rounded-0`
                    }
                >
                    <Container>
                        <h1 className="font-weight-bold">{pageTitle}</h1>
                    </Container>
                </Jumbotron>
            )}
            <Container style={{ ...style, overflow: "hidden" }} fluid={fluid}>
                {children}
            </Container>
            <Footer />
        </>
    )
}

export default Layout
