import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

const Section = ({ bg, children, className, style, fluid, centered }) => {
    const removeEdges = {
        marginRight: "-15px",
        marginLeft: "-15px",
    }

    return (
        <div style={style} className={`section bg-${bg} ${className}`}>
            <Container
                fluid={fluid}
                style={fluid && removeEdges}
                className={
                    centered
                        ? "d-flex justify-content-center align-items-center"
                        : ""
                }
            >
                {children}
            </Container>
        </div>
    )
}

Section.propTypes = {
    bg: PropTypes.string,
}

export default Section
